import { GetServerDataProps, GetServerDataReturn } from 'gatsby';
import React from 'react';

import Vacancy from '../../components/vacancy';
import { GLOBAL_QUERY } from '../../constants';
import { TPageProp } from '../../types';
import { IGlobalServerRuntimeData } from '../../types/strapi/global';
import { IVacancy } from '../../types/strapi/vacancies';
import { IVacancyPage } from '../../types/strapi/vacancyPage';

type TNode = Pick<IVacancyPage, 'isHidden' | 'pageId'| 'count'| 'countText'| 'headerBottom'| 'seo'| 'textBottom'| 'video' >;

interface IServerRuntimeData extends IGlobalServerRuntimeData {
    vacancyPage: TNode,
    vacancy: IVacancy
}

type TProps = TPageProp<IServerRuntimeData>;

const VacancyPage: React.FC<TProps> = ({ serverData }) => {
    if(!serverData?.vacancy) {
        return null;
    }

    return <Vacancy data={{
        vacancyPageProps: serverData.vacancyPage,
        vacancyProps    : serverData.vacancy,
        global          : serverData.global,
        navPanel        : serverData.navPanel,
        respondForm     : serverData.respondForm,
        footer          : serverData.footer
    }}
    />;
};

export default VacancyPage;

const query = `
  query VacancyPage($locale: String, $id: ID!) {
    ${GLOBAL_QUERY}
    vacancyPage(locale: $locale) {
      isHidden
      seo {
        metaTitle
        metaDescription
        shareImage {
          url
        }
      }
      pageId,
      headerBottom
      textBottom
      count
      countText
      video {
        url
      }
    }
    vacancy (id: $id) {
      isArchived
      isSecret
      about
      area {
        text
      }
      city {
        text
      }
      conditions
      customDescription
      direction {
        id
        header
      }
      jobType {
        duration
        text
      }
      tags {
        id
        text
      }
      title
      whatToDo
      whatWaitingFor
      customDescriptionHeader
      conditionsHeader
      plussesHeader
      plusses
      whatToDoHeader
      whatWaitingForHeader
      huntflowId
      huntflowId2
    }
  }
`;

export async function getServerData({ params }: GetServerDataProps): Promise<GetServerDataReturn<IServerRuntimeData>> {
    try {
        const variables = JSON.stringify({
            locale: 'ru',
            id    : params?.id
        });
        const response = await fetch(
            `${process.env.GATSBY_API_URL}/graphql?query=${query}&variables=${variables}`
        );

        const data: { data: IServerRuntimeData } = await response.json();

        if(data.data.vacancyPage.isHidden) {
            return {
                status: 404
            };
        }

        if(!data.data.vacancy || data.data.vacancy.isArchived || data.data.vacancy.isSecret) {
            return {
                status : 301,
                headers: { 'location': '/', 'cache-control': 'public, max-age=0, must-revalidate' }
            };
        }

        return {
            props: {
                vacancyPage: data.data.vacancyPage,
                vacancy    : data.data.vacancy,
                navPanel   : data.data.navPanel,
                global     : data.data.global,
                respondForm: data.data.respondForm,
                footer     : data.data.footer
            }
        };
    } catch(error) {
        return {
            status: 500
        };
    }
}

